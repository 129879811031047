@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* add primeng css here */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@font-face {
  font-family: Outfit;
  src: url("assets/fonts/Outfit-VariableFont_wght.ttf") format("truetype");
}

/* html {} */

:root {
  font-family: "Outfit", sans-serif !important;
}

body,
.p-component {
  font-family: "Outfit", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: normal;
  font-size: 16px;
}

.p-overlay.p-component {
  max-width: 100%;
}

/* p-overlay > div {
    border: solid 1px red;
    min-height: 150px;
    background-color: #fff;
} */
body.bodybg {
  background: url(assets/images/bodybg.png) no-repeat center;
  background-size: cover;
}

body.bodybg #main {
  margin-top: 62px;
  padding: 0px 0px;
  transition: all 0.3s;
  min-height: calc(100vh - 108px);
  max-height: calc(100vh - 108px);
  overflow: auto;
}

#main main {
  margin: 0 !important;
  min-height: auto !important;
  max-height: auto !important;
}

#main main .divRepresent {
  display: none;
}

#main main .container-fluid {
  padding: 0 !important;
  overflow: hidden !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 30px !important;
  padding-left: 30px !important;
}

a.a-plus {
  color: #212121 !important;
}

a.a-minus {
  color: #e60000 !important;
}

.text-danger {
  color: #e60000 !important;
}

nav.bg-light {
  box-shadow: 0 0.1875rem 0.5rem 0 rgba(34, 48, 62, 0.1);
  border-bottom: 2px solid #a0d5e3;
  background-color: transparent !important;
}

.spinner-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

/* Custome Scroll bar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: rgba(211, 212, 242, 0.9);
  border-radius: 3px;
  width: 0.1vw;
}

::-webkit-scrollbar-thumb {
  background-color: #707070;
  border-radius: 3px;
  width: 0.6vw;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #000;
}

/* Custome Scroll bar */

.fixed-top {
  z-index: 1000 !important;
}

h1 {
  color: #212121;
  font-size: 26px;
}

#main .card:last-child {
  margin-bottom: 0;
}

/* .pageTitle {
    margin-bottom: .4rem;
} */

.card {
  border-radius: 10px !important;
  box-shadow: 0 0.1875rem 0.5rem 0 rgba(34, 48, 62, 0.1);
}

.card-body {
  padding: 12px 12px !important;
}

.card.card-output {
  background-color: #def6ff !important;
}

.card.card-output.fixed {
  right: 180px;
  z-index: 999;
  top: 60px;
  border-radius: 0 0 10px 10px !important;
  box-shadow: 0 0.1875rem 0.5rem 0 rgba(34, 48, 62, 0.1);
  position: absolute;
}

.alert {
  position: relative;
  padding: 0.5rem 1rem !important;
  margin-bottom: 1rem !important;
  border: 1px solid transparent;
  border-radius: 10px;
  font-size: 14px;
}

.alert i {
  margin-right: 0.5rem;
}

.alert-primary {
  color: #707070;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}

.alert-primary i {
  color: #212121;
  font-size: 18px;
}

.profile {
  border-radius: 20px;
  padding: 10px;
  border: solid 1px #212121;
  min-width: 300px;
  color: #212121;
}

.profile h6 {
  color: #212121;
  font-size: 24px !important;
  font-weight: 500 !important;
}

.profile small {
  color: #707070;
  font-size: 14px;
}

.divRole {
  color: #212121;
  font-size: 16px;
  margin-top: 1.5rem;
}

.profile a {
  color: #cc0028 !important;
  background: #fff !important;
  width: auto;
}

.modal-title {
  font-weight: 500 !important;
}

.modal-backdrop.show {
  background-color: #435971 !important;
}

.login-wrapper {
  align-items: flex-start;
}

.login-wrapper {
  display: flex;
  flex-basis: 100%;
  /*min-height: 100vh;*/
  width: 98%;
  min-height: calc(100vh - 40px);
  /* padding: 2%; */
  /* background: beige url("assets/images/login-bg.jpg"); */
  border-radius: 30px;
  margin: 20px auto;
  background-size: cover;
}

.login-wrapper .login-inner {
  --background-color: #fff;
}

.login-wrapper .login-inner {
  width: 100%;
  min-height: calc(100vh - 80px);
}

ul.header-nav {
  list-style: none;
  margin-bottom: 0;
}

.navbar-brand {
  padding: 2px 0 !important;
}

.navbar-brand {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #212121 !important;
}

.navbar-brand img {
  max-height: 38px;
  max-width: 154px;
}

.navbar-brand .divider {
  height: 34px;
  width: 1px;
  border-right: solid 1px #edeaf2;
  margin: 0 20px;
  display: inline;
}

/* .navbar-brand span {
        border-left: solid 1px #EDEAF2;
        padding-left: 20px;
        margin-left: 20px;
    } */

.app-brand-link img {
  max-height: 30px;
}

.navbar-brand div {
  margin-left: 50px;
  display: block;
  font-size: 12px;
  letter-spacing: 5.5px;
  margin-top: -6px;
}

.app-brand-link div {
  margin-top: -6px;
  display: block;
  font-size: 12px;
  color: white;
  margin-left: 60px;
  letter-spacing: 8.4px;
}

.page-wrapper {
  min-height: calc(100vh - 138px);
}

.navbar-dark {
  background-color: transparent !important;
  margin-top: 4px;
  background-color: #152147;
  padding: 0px 0px;
  /*box-shadow: 0px 2px 20px rgb(1 41 112 / 10%);*/
  margin-bottom: -25px;
}

.navbar-dark .container-fluid {
  background-color: #171a39;
  /*background: linear-gradient(45deg,#7b1fa2,#7c4dff) !important;*/
  /*background: linear-gradient(to right,#8971ea,#7f72ea,#7574ea,#6a75e9,#5f76e8) !important;*/
  /*background: linear-gradient(to right,#496096,#4a609d) !important;*/
  /*background: linear-gradient(to right,#466497,#495f9e) !important;*/
  border-radius: 4px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

header {
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 0px 0px;
}

.header a {
  color: #171a39 !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-item:hover,
.navbar-nav .nav-link.dropdown:hover,
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link > li > a:hover {
  font-weight: 400 !important;
  color: #212121 !important;
  /*border-bottom: solid 2px #BC0202 !important;*/
  background: url("assets/images/menu_hover.png") no-repeat bottom center;
  background-size: 100% 5px;
}

.navbar li.nav-item > a {
  font-weight: 400 !important;
  /* font-size: 1rem; */
  margin: 0 20px !important;
  padding: 0 0px !important;
  line-height: 50px;
}

.navbar li.nav-item > a:hover {
  /* font-size: 1rem; */
  line-height: 50px;
}

.navbar li.nav-item .nav-icon {
  font-size: 1.25rem !important;
}

.nav-link {
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out !important;
}

nav.navbar-dark .nav-link:hover,
nav.navbar-dark .nav-link.active,
nav.navbar-dark .show > .nav-link {
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: #fff !important;
}

.nav-item ul {
  border: solid 1px #212121;
}

.nav-tabs {
  background-color: #212121;
  border-radius: 8px;
  max-width: 24%;
  min-width: 280px;
  padding: 0px;
}

.nav-profile img {
  max-height: 20px;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 1) !important;
}

/* SubMenu */
.dropdown-item {
  white-space: normal !important;
}

ul.subMenu {
  padding: 0px 0 !important;
  border-radius: 0 0px 6px 6px !important;
  border: solid 1px #212121 !important;
  border-top: solid 0px #212121 !important;
  /* box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 40%) !important; */
  box-shadow: 0 0.1875rem 0.5rem 0 rgba(34, 48, 62, 0.1) !important;
  margin-top: 0px;
  /* max-width: 200px; */
}

ul.subMenu:before {
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 7px;
}

.subMenu li a,
.subMenu li .dropdown-item {
  color: #212121;
  padding: 0.5rem !important;
  font-size: 90%;
}

.subMenu li a:hover,
.subMenu li .dropdown-item:hover {
  background-color: #212121 !important;
  color: #fff !important;
}

.dropdown-item.active,
.subMenu li .dropdown-item.active .dropdown-item:active,
.subMenu li .dropdown-item:active {
  background-color: #212121 !important;
  color: #fff !important;
}

.subMenu li:first-child a,
.subMenu li:first-child .dropdown-item {
  border-radius: 0px 0px 0 0;
}

.subMenu li:only-child a,
.subMenu li:only-child .dropdown-item {
  border-radius: 0 0 6px 6px !important;
}

.subMenu li:last-child a,
.subMenu li:last-child .dropdown-item {
  border-radius: 0 0 6px 6px;
}

ul.header-nav {
  padding-left: 0 !important;
}

.divloginContainer {
  width: 420px !important;
}

.imglogo {
  max-height: 34px;
}

.quotes {
  max-height: 24px;
  margin: 1.5rem 0 0.5rem 0 !important;
}

.copyright img {
  max-height: 30px;
}

.copyright {
  font-size: 12px;
  font-weight: 400;
  color: #707070 !important;
}

/* Custom style for dateatable */
.table {
  width: 100%;
}

.table.dataTable {
  border-collapse: separate !important;
  border-spacing: 0 0.5em !important;
}

.table.tablewebsite tr td {
  padding: 2px 6px;
}

.table tbody tr {
  border-radius: 14px !important;
}

.form-switch .form-check-input {
  border-radius: 0.3em;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #fff;
}

table.dataTable th,
table.dataTable td {
  box-sizing: content-box;
  border-bottom: none;
}

/* Responsive Table Style */
.responsive-table {
  background-color: #fefefe;
  border-collapse: collapse;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
  width: 100%;
  margin: 10px 0;
  overflow: hidden;
}

.responsive-table__row {
  display: grid;
  border-bottom: 1px solid #edeef2;
  padding: 0 1.5rem;
}

.spnformLabel_sml {
  display: none;
}

.table-container {
  /* max-height: 252px; */
  /* max-height: calc(100vh - 590px); Adjust the height as needed */
  /* min-height: 158px; */
  overflow-y: auto;
  /* border: 1px solid #ddd; */
  /* Optional: Add a border for better visualization */
}

.pdf-container {
  max-height: calc(100vh - 410px);
  /* Adjust the height as needed */
  /*  height: calc(100vh - 500px); Adjust the height as needed */
  min-height: 150px;
  width: 100%;
  /* Adjust the width as needed, or use a fixed width */
  overflow: auto;
  /* Enable both vertical and horizontal scrolling */
  margin-top: 1rem;
}

.table-container {
  min-height: 166px !important;
}

@media (max-width: 1022px) {
  .spnformLabel {
    display: none;
  }

  .spnformLabel_sml {
    display: block;
  }
}

@media (max-height: 584px) {
  .table-container {
    /* max-height: 122px !important; */
    min-height: 122px !important;
  }

  .pdf-container {
    max-height: calc(100vh - 360px) !important;
  }

  .pdf-container pdf-viewer {
    height: calc(100vh - 160px) !important;
  }
}

/* Display Resolution: 1920 X 1080 & Scale: 150%  */
@media (min-height: 585px) {
  .table-container {
    max-height: 196px !important;
  }
}

.lblValidated {
  height: 27.79px;
}

.lblValidated button {
  color: blue;
  cursor: pointer;
  border: none;
  background-color: transparent !important;
}

/* Display Resolution: 1920 X 1080 & Scale: 125%  */
@media (min-height: 598px) {
  .table-container {
    max-height: 166px !important;
  }

  .pdf-container {
    max-height: calc(100vh - 440px);
  }

  /* .pdf-container {
         max-height: 196px !important;
    } */

  .pdf-container pdf-viewer {
    min-height: calc(100vh - 480px) !important;
  }
}

/* Display Resolution: 1920 X 1080 & Scale: 100%  */
@media (min-height: 727px) {
  .table-container {
    max-height: 206px !important;
  }

  .pdf-container {
    min-height: calc(100vh - 480px) !important;
  }

  /* .pdf-container pdf-viewer {
        min-height: 218px !important;
    } */
}

/* @media (max-width: 1280px) {

    body,
    .p-component,
    .table,
    .nav-tabs .nav-link,
    .form-control-label {
        font-size: 14px !important;
    }

} */

@media (min-width: 1024px) {
  .nav-tabs {
    width: 100%;
  }

  .responsive-table__row {
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .responsive-table__row {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.responsive-table__row th,
.responsive-table__row td {
  padding: 0.5rem;
}

.responsive-table__head {
  background-color: #212121;
  color: #fff;
}

@media (max-width: 991px) {
  .responsive-table__head {
    display: none;
  }
}

.responsive-table__head__title {
  display: flex;
  align-items: center;
  font-weight: 500;
  text-transform: capitalize;
}

.responsive-table__body .responsive-table__row {
  transition: 0.1s linear;
  transition-property: color, background;
  border-bottom: 1px solid #d5d6d8;
}

.responsive-table__body .responsive-table__row:last-child {
  border-bottom: none;
}

.responsive-table__body .responsive-table__row:hover {
  color: #212121;
  background-color: #c2f0f7;
}

.responsive-table__body__text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.responsive-table__body__text::before {
  margin-right: 1rem;
  font-weight: 600;
  text-transform: capitalize;
}

@media (max-width: 991px) {
  .responsive-table__body__text::before {
    content: attr(data-title) " :";
  }
}

@media (max-width: 400px) {
  .responsive-table__body__text::before {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.responsive-table__body__text--name {
  font-weight: 600;
}

@media (min-width: 768px) {
  .responsive-table__body__text--name::before {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .responsive-table__body__text--name {
    grid-column: 1/2;
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .responsive-table__body__text--status,
  .responsive-table__body__text--types,
  .responsive-table__body__text--update {
    grid-column: 2/3;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .responsive-table__body__text--country {
    grid-column: 3/-1;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .responsive-table__body__text--name,
  .responsive-table__body__text--country {
    grid-row: 2;
  }
}

.notestyle {
  border: 1px solid #dedfeb;
  padding: 10px;
  background: #eaebf2;
  box-shadow:
    0 -1px 0 rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  border-radius: 3px;
}

/* SVG Up Arrow Style */
.up-arrow {
  height: 100%;
  max-height: 1.8rem;
  margin-left: 1rem;
}

/* SVG User Icon Style */
.user-icon {
  width: 100%;
  max-width: 4rem;
  height: auto;
  margin-right: 1rem;
}

#item_table.table > :not(caption) > * > * {
  border: 0px;
}

/* Status Indicator Style */
.status-indicator {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: #222222;
  margin-right: 0.5rem;
}

.status-indicator--active {
  background: #25be64;
}

.status-indicator--inactive {
  background: #dadde4;
}

.status-indicator--new {
  background: #febf02;
}

.loginContainer {
  padding: 30px 24px;
  border-radius: 0px;
  /*overflow: hidden;*/
  background: #fff;
  border-radius: 20px;
  -webkit-box-shadow: -4px 0px 13px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -4px 0px 13px -5px rgba(0, 0, 0, 0.75);
  box-shadow: -4px 0px 13px -5px rgba(0, 0, 0, 0.75);
  /*height: calc(100vh - 0px);*/
}

.login-with-google-btn {
  transition:
    background-color 0.3s,
    box-shadow 0.3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 0px;
  box-shadow:
    0 -1px 0 rgba(0, 0, 0, 0.04),
    0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  color: #444;
  border: thin solid #888;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url("assets/images/google.png");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 5px 5px;
  margin-bottom: 10px;
}

.login-with-google-btn:hover {
  box-shadow:
    0 -1px 0 rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.25);
}

.login-with-google-btn:active {
  background-color: #eeeeee;
}

.login-with-google-btn:focus {
  outline: none;
  box-shadow:
    0 -1px 0 rgba(0, 0, 0, 0.04),
    0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow:
    0 -1px 0 rgba(0, 0, 0, 0.04),
    0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.chatloginbg {
  /*background-image: url("assets/images/bg_ptrn.jpg");
    background-repeat: repeat;*/
  /* background-color: #fff; */
  backdrop-filter: saturate(180%) blur(10px);
}

.chat-title {
  font-size: 18px;
  margin-left: 20px;
}

.chatboxheader {
  background: rgba(211, 212, 242, 0.2);
  border-bottom: solid 1px #19244e;
  position: relative;
  min-height: 70px;
}

.outlook-icon {
  background-image: url("assets/images/office365.png");
  transition:
    background-color 0.3s,
    box-shadow 0.3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 0px;
  box-shadow:
    0 -1px 0 rgba(0, 0, 0, 0.04),
    0 1px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #d73b02;
  background-repeat: no-repeat;
  background-position: 5px 5px;
}

.chatboxbody {
  color: #212121;
  position: relative;
  min-height: calc(100vh - 281px);
  max-height: calc(100vh - 281px);
  overflow-y: scroll;
  overflow: auto;
  margin: 10px 15px 10px 25px;
  /* margin-right: 10px; */
  font-size: 18px;
}

.logo-bottom {
  bottom: 10px;
}

.smallbot img {
  width: 80px;
  position: absolute;
  animation: heartbeat 1s infinite alternate;
  right: 16px;
  bottom: 0px;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.botalign {
  position: absolute;
  bottom: 0px;
  animation: heartbeatm 3s infinite alternate;
  right: 42px;
  text-align: right;
}

.botalign img {
  max-width: 30%;
}

.chat-btn-close {
  position: absolute;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  opacity: 1;
  top: 2px;
  right: 0px;
  font-size: 50px;
  color: #cc0000;
}

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  /*max-width: 867px;*/
  margin: 0px 0px 25px 0px;
  height: calc(100vh - 306px);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding-right: 4px;
  width: 100%;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.msg:last-of-type {
  margin: 0;
}

.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.msg-info-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}

.msg-info-time {
  font-size: 0.85em;
  font-weight: 500;
  color: #000;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.left-msg .msg-text {
  background: rgba(211, 212, 242, 0.7);
  padding: 6px 12px;
  border-radius: 10px;
  margin-right: 40px;
}

.right-msg {
  flex-direction: row-reverse;
}

.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  color: #212121;
  border-bottom-right-radius: 0;
}

.right-msg .msg-text {
  background: #cee2f3;
  padding: 10px;
  border-radius: 10px;
  margin-left: 40px;
  line-height: 18px;
  /* font-size: .9rem; */
}

.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 10px 30px 10px 25px;
  border-top: var(--border);
}

.msger-inputarea textarea {
  border: 1px solid #d5d6d8;
  border-radius: 10px 0px 0px 10px;
  width: 100%;
}

.msger-inputarea * {
  padding: 10px;
  border: none;
  font-size: 1em;
}

.msger-input {
  flex: 1;
  background: #ddd;
}

.msger-mic-btn {
  padding: 0 10px;
  margin: 0 10px;
  border: none;
  background: #fff;
  color: #212121;
  font-weight: bold;
  border-radius: 0px;
  cursor: pointer;
  transition: background 0.23s;
}

/*
    .msger-mic-btn:hover {
        background: #ceeff4;
    }*/

.msger-send-btn {
  padding: 0px 10px;
  background: #fff;
  color: #212121;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
  border: none;
}

/*
    .msger-send-btn:hover {
        background: #ceeff4;
    }*/

a:link,
a:visited {
  color: #444;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}

.credit {
  background: #fff;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 20px;
}

/* Starts Chat */

#whatsapp-chat {
  position: fixed;
  border-radius: 15px;
  -webkit-box-shadow: -4px 0px 13px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -4px 0px 13px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 6px #00000029;
  bottom: 30px;
  right: 0px;
  overflow: hidden;
  z-index: 99;
  animation-name: showchat;
  animation-duration: 1s;
  transform: scale(1);
  height: calc(100% - 110px);
  margin: 0px 30px 10px 30px;
}

a.blantershow-chat,
a.blantershow-chat-left {
  background-color: #5100cc;
  color: #fff;
  position: fixed;
  z-index: 98;
  bottom: 40px;
  right: 30px;
  font-size: 15px;
  /*padding: 13px 7px 9px 15px;*/
  border-radius: 60px;
  box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
  height: 60px;
  width: 60px;
}

a.blantershow-chat i {
  transform: scale(1.2);
  margin: 0 10px 0 0;
}

.msger-mic-btn {
  margin-left: 0px;
  border-bottom: 1px solid #d5d6d8;
  border-top: 1px solid #d5d6d8;
  border-right: 1px solid #fff;
  background: #fff;
  color: #212121;
  font-weight: bold;
  border-radius: 0px;
  cursor: pointer;
  transition: background 0.23s;
}

.msger-mic-btn:hover {
  background: #ceeff4;
}

.header-chat {
  /* background: #FFD9D8; */
  /* color: #fff; */
  font-size: 18px !important;
}

.header-chat h3 {
  margin: 0 0 10px;
}

.header-chat p {
  font-size: 14px;
  line-height: 1.7;
  margin: 0;
}

.info-avatar {
  position: relative;
}

.info-avatar img {
  border-radius: 100%;
  width: 50px;
  float: left;
  margin: 0 10px 0 0;
}

.info-avatar:before {
  content: "\f232";
  z-index: 1;
  font-family: "Font Awesome 5 Brands";
  background: #23ab23;
  color: #fff;
  padding: 4px 5px;
  border-radius: 100%;
  position: absolute;
  top: 30px;
  left: 30px;
}

a.informasi {
  padding: 20px;
  display: block;
  overflow: hidden;
  animation-name: showhide;
  animation-duration: 2.5s;
}

a.informasi:hover {
  background: #f1f1f1;
}

.info-chat span {
  display: block;
}

#get-label,
span.chat-label {
  font-size: 12px;
  color: #888;
}

#get-nama,
span.chat-nama {
  margin: 5px 0 0;
  font-size: 15px;
  font-weight: 700;
  color: #222;
}

#get-label,
#get-nama {
  color: #fff;
}

span.my-number {
  display: none;
}

.blanter-msg {
  color: #444;
  padding: 20px;
  font-size: 12.5px;
  text-align: center;
  border-top: 1px solid #ddd;
}

/* textarea#chat-input {
    border: none;
    font-family: 'Arial', sans-serif;
    width: 100%;
    height: 20px;
    outline: none;
    resize: none
} */

a#send-it {
  color: #555;
  width: 40px;
  margin: -5px 0 0 5px;
  font-weight: 700;
  padding: 8px;
  background: #eee;
  border-radius: 10px;
}

.first-msg {
  background: #f5f5f5;
  padding: 30px;
  text-align: center;
}

.first-msg span {
  background: #e2e2e2;
  color: #333;
  font-size: 14.2px;
  line-height: 1.7;
  border-radius: 10px;
  padding: 15px 20px;
  display: inline-block;
}

.start-chat .blanter-msg {
  display: flex;
}

#get-number {
  display: none;
}

a.close-chat {
  position: absolute;
  top: 12px;
  right: 20px;
  color: #504070;
  font-size: 34px;
}

@media screen and (max-width: 480px) {
  #whatsapp-chat {
    width: auto;
    left: 5%;
    right: 5%;
    font-size: 80%;
  }
}

.hide {
  display: none;
  animation-name: showhide;
  animation-duration: 1.5s;
  transform: scale(1);
  opacity: 1;
}

.show {
  display: block;
  animation-name: showhide;
  animation-duration: 1.5s;
  transform: scale(1);
  opacity: 1;
}

#record-button {
  background-color: #ffffff;
}

#record-option {
  background-color: #ffffff;
}

.red-record {
  color: red !important;
  background-color: #ffffff;
}

.hide {
  display: none;
  animation-name: showhide;
  animation-duration: 1.5s;
  transform: scale(1);
  opacity: 1;
}

.show {
  display: block;
  animation-name: showhide;
  animation-duration: 1.5s;
  transform: scale(1);
  opacity: 1;
}

.hide {
  display: none;
  animation-name: showhide;
  animation-duration: 1.5s;
  transform: scale(1);
  opacity: 1;
}

.show {
  display: block;
  animation-name: showhide;
  animation-duration: 1.5s;
  transform: scale(1);
  opacity: 1;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #6cad96;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #6cad96;
  }

  28% {
    transform: translateY(-7px);
    background-color: #9ecab9;
  }

  44% {
    transform: translateY(0px);
    background-color: #b5d9cb;
  }
}

/* .suggestion-btn {
    padding: 10px;
    margin: 5px;
    border-radius: 4px;
    background: #fff;
    border: 0px;
  } */

/* Apply rounded border, transparent background, glow effect, and wounded border to suggestion button */
.suggestion-btn {
  padding: 10px;
  margin: 5px;
  border-radius: 20px;
  background: white;
  border: 2px solid transparent;
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.5);
  transition: box-shadow 0.3s ease;
}

/* Apply hover effect to change border color on hover */
.suggestion-btn:hover {
  border-color: #ff0000;
}

/*custom css edited on 03/28/2024 start here*/

.chatboxheader img {
  height: 50px;
  border-radius: 50px;
  width: 50px;
  margin-left: 20px;
  margin-top: 13px;
  text-align: end;
  border: 2px solid #fff;
}

.head-home p {
  position: absolute;
  left: 80px;
  top: 23px;
  font-size: 18px;
  font-weight: 500;
}

#circle-doted {
  position: absolute;
  background-color: #00bda5;
  border: 2px solid #fff;
  height: 12px;
  width: 12px;
  border-radius: 20px;
  left: 49px;
  top: 40px;
}

.bi-chat-square-text-fill::before {
  font-size: 28px;
  margin-left: 14px;
  margin-top: 18px;
}

.right-text-img {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0;
  border-radius: 50px;
}

.popup-messages {
  display: inline-block;
  background-color: #d3d4f2;
  border-color: rgb(38, 184, 206);
  border: 1px none rgb(38, 184, 206);
  margin-bottom: 10px;
  color: #000;
  padding: 5px 12px;
  border-radius: 7px;
  margin-left: 7px;
  line-height: 14px;
  font-size: 12px;
  margin-right: 0px;
  margin-top: 7px;
  font-weight: 500;
}

.divChatmessage {
  margin: 10px auto 0 auto;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #d5d5d5;
  border-radius: 15px;
  padding: 0.5rem;
  width: 96% !important;
}

.divChatmessage textarea {
  border: 0;
  /* height: 54px; */
  resize: none;
}

.divChatmessage textarea:focus-visible {
  border: 0 !important;
  box-shadow: none !important;
  outline: 0;
}

.greetings {
  background: rgba(255, 255, 255, 0.6);
  padding: 0.2rem;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 0.6rem;
}

a.blantershow-chat-left {
  right: auto;
  left: 30px;
}

a.blantershow-chat i {
  transform: scale(1.2);
  margin: 0 10px 0 0;
}

div#whatsapp-chat.left {
  left: 0px !important;
  right: auto;
}

/* Ends Chat */

.hide {
  display: none;
  animation-name: showhide;
  animation-duration: 1.5s;
  transform: scale(1);
  opacity: 1;
}

.show {
  display: block;
  animation-name: showhide;
  animation-duration: 1.5s;
  transform: scale(1);
  opacity: 1;
}

.is-invalid {
  border-color: red;
}

.popup-messages {
  display: inline-block;
  background-color: transparent;
  border-color: rgb(38, 184, 206);
  border: 1px solid rgb(38, 184, 206);
  margin-bottom: 10px;
  color: black;
  padding: 4px 8px;
  border-radius: 7px;
  margin-left: 7px;
  line-height: 14px;
  font-size: 0.7rem;
  margin-right: 0px;
  margin-top: 7px;
}

.pg-footer {
  position: relative !important;
}

.loader-overlay {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
  position: absolute;
  top: 40%;
  left: 45%;
  display: block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.font-14 {
  font-size: 14px;
}

.font-13 {
  font-size: 13px;
  color: #838383;
  font-weight: 500;
}

.small-text-custom {
  font-size: 12px;
  color: #707070;
}

.minus-margin {
  margin-top: -3px;
  margin-right: 6px;
}

.dataTables_wrapper .dataTables_filter input {
  border: 1px solid #fff !important;
  border-radius: 3px;
  padding: 5px;
  background-color: #fff !important;
  margin-left: 3px;
  box-shadow: 0px 0px 4px 0px #adadad;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 2px 10px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: #fff !important;
  border: none !important;
}

.table-responsive {
  z-index: 999;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #fff;
  color: var(--bs-table-striped-color);
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: #fff;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table {
  margin: 0px 0 !important;
  font-size: 16px;
  width: 100% !important;
}

td a {
  color: #007acc !important;
  text-decoration: none !important;
}

.table td .ico img {
  max-width: 18px;
}

.formContent {
  max-height: calc(100vh - 255px) !important;
  overflow-x: hidden;
  overflow-y: auto;
}

/*
.saveicon {
    background: url('assets/images/SaveIco.png') no-repeat center;
    background-image: url(assets/images/SaveIco.png);
    background-repeat: no-repeat;
    background-size: 20px;
    height: 19px;
    width: 24px;
    display: inline-block;
}
.btn-save {
    content: "";
    background-image: url(assets/images/SaveIco.png);
    background-repeat: no-repeat;
    background-size: 20px;
    transition: transform .2s ease-in-out;
    padding: 4px 12px 4px 30px;
    background-position: 10px center;
}*/
/* Custom style for dateatable */
.dt-scroll {
  margin: 0 0 0.6rem 0;
}

.dt-scroll-body thead {
  display: none;
}

.dt-scroll-body {
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 375px) !important;
  height: auto !important;
}

.dt-scroll-headInner {
  padding-right: 0 !important;
}

.dt-info {
  float: left;
  opacity: 0.8;
  font-size: 90%;
  margin: 5px 10px 0 10px !important;
}

.dt-length {
  float: left !important;
}

.dt-paging {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  float: right;
  margin-right: 30px;
}

/* 
.dt-search {
    float: left !important;
    margin-left: 1.5rem !important;
    margin-bottom: .6rem !important;
} */

.dt-search input {
  background: transparent !important;
  padding: 0.35rem 0.75rem 0.35rem 2rem !important;
  border: 1px solid #ced4da !important;
  border-radius: 10px !important;
  font-size: 90% !important;
  width: 100% !important;
  background-image: url(assets/images/searchico.png) !important;
  background-repeat: no-repeat !important;
  background-size: 16px !important;
  background-position: 8px 8px !important;
}

div.dt-container .dt-paging .dt-paging-button.current,
div.dt-container .dt-paging .dt-paging-button.current:hover,
div.dt-container .dt-paging .dt-paging-button:hover,
div.dt-container .dt-paging .dt-paging-button:hover {
  background: #ffffff !important;
  border: solid 1px #ffffff !important;
  padding: 0.5em 1em !important;
  color: #707070 !important;
}

div.dt-container .dt-paging .dt-paging-button {
  font-family: "Outfit";
  font-size: 14px;
  font-weight: 500;
}

/*.dt-paging-button.first{
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: 400 !important;
    
}
    .dt-paging-button.first:before {
        content: "\F27F";
        display: inline-block;
    }*/
/* Custom style for dateatable */
#clientsTable {
  border-collapse: separate;
  border-spacing: 0 0.5em;
  border-radius: 14px !important;

  margin-top: -8px !important;
  width: 99.7%;
}

/* #clientsTable tr td:first-child,
#clientsTable tr th:first-child {
    border-radius: 20px 0 0 20px;
} */

#clientsTable .table tr td,
#clientsTable .table tr th {
  border-radius: 14px !important;
  padding: 10px !important;
}

.table_DataExtraction.table_Content thead tr th:first-child {
  border-radius: 0px 0 0 0px !important;
}

.table_DataExtraction.table_Content thead tr th:last-child {
  border-radius: 0 0px 0px 0 !important;
}

tr th:first-child {
  border-radius: 0px 0 0 10px;
}

tr th:last-child {
  border-radius: 0 0px 10px 0;
}

tr td:first-child {
  border-radius: 10px 0 0 10px;
}

tr td:last-child {
  border-radius: 0 10px 10px 0;
}

.table.tableCard {
  border-spacing: 0 !important;
}

.table.tableCard tr th:first-child,
.table.tableCard tr th:last-child,
.table.tableCard tr td:first-child,
.table.tableCard tr td:last-child {
  border-radius: 0 !important;
}

.form-switch .form-check-input {
  border-radius: 0.3em;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #fff;
}

table.dataTable th,
table.dataTable td {
  box-sizing: content-box;
  border-bottom: none;
}

.nav-tabs {
  background-color: #212121;
  border-radius: 8px;
  max-width: 24%;
  padding: 0px;
}

.nav-tabs .nav-link {
  font-size: 16px;
  padding: 0.6rem 1.4rem;
  border-top: solid 3px #212121;
  border-bottom: solid 3px #212121;
  margin-bottom: 0px;
  transition: all 375ms ease-out;
}

.nav-tabs .nav-link:hover {
  color: #fff !important;
  border-top: solid 3px #cc0028 !important;
  border-bottom: solid 3px #cc0028 !important;
  transition:
    transform 250ms cubic-bezier(0, 0.5, 0.5, 1.1),
    background 250ms ease-out;
}

.divWebsitePreview {
  margin-top: -65px;
  border-radius: 25px;
  height: calc(100vh - 190px) !important;
  /* overflow: auto; */
}

.divWebsitePreview .card-body {
  /* margin-top: -65px; */
  border-radius: 25px;
  height: calc(100vh - 190px) !important;
  overflow: auto;
}

/*.divWebsitePreview .card-body {
        background: url(assets/images/WebsitePreview.png) no-repeat top;
        background-size: contain;
    }*/

.nav-link {
  color: #fff;
  font-weight: 500;
}

.nav-link:hover {
  color: #cc0028;
  font-weight: 500;
}

.nav-link:focus {
  color: #cc0028;
  font-weight: 500;
}

#clientsTable_filter {
  float: left;
}

.dataTables_wrapper .dataTables_length {
  float: right;
  display: contents !important;
}

.dataTables_wrapper .dataTables_length label {
  margin-left: 10px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: transparent;
  border-color: none;
  border-top: 3px solid #cc0028;
  border-bottom: 3px solid #cc0028;
  border-left: none;
  border-right: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
  outline: none;
}

.nav-tabs .nav-link:focus {
  border-color: transparent;
  border-top: 3px solid #cc0028;
  border-bottom: 3px solid #cc0028;
  outline: none;
}

.mandatory::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.form-label {
  margin-bottom: 6px;
}

.form-control-label {
  font-size: 16px;
  color: #707070;
}

.form-control.text-right {
  text-align: end;
}

.form-control,
.p-inputtext,
.form-select,
.p-multiselect,
.multiselect-container {
  border-radius: 5px;
  /* border: solid 1px #DCDCDC !important; */
  color: #212121;
  font-size: 16px !important;
  padding: 0.09rem 0.4rem !important;
}

.p-inputswitch-checked .p-inputswitch-slider {
  background: #fff;
  border: solid 1px #bfbfbf;
}

p-dropdown > div {
  width: 100%;
}

.form-select {
  padding: 0.09rem 2.25rem 0.09rem 0.4rem !important;
}

.p-multiselect {
  width: 100%;
  margin-top: 3px;
  /*  line-height: 1.5;
    height: 25.65px; */
}

.form-control:focus,
.p-inputtext:focus,
.form-select:focus {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #707070 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.form-select {
  background-position: right 0.25rem center !important;
}

.highlight .p-inputtext {
  border-color: red !important;
}

.small-box-green {
  background-color: #5fcc00;
  height: 15px;
  width: 15px;
  border-radius: 3px;
  margin-left: 6px;
  margin-top: 5px;
}

.small-box-red {
  background-color: #cc0028;
  height: 15px;
  width: 15px;
  border-radius: 3px;
  margin-left: 6px;
  margin-top: 5px;
}

.custom-green {
  color: #208ad2;
}

/*.add-customer-btncustom {
    position: absolute;
    display: block;
    right: 10px;
    margin-top: 10px;
    z-index: 999;
}*/

.form-check-input:checked {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.input-group-text {
  background-color: white !important;
}

.p-radiobutton .p-radiobutton-box {
  border: 1px solid #d1d5db;
  /* background: #ffffff; */
  width: 16px;
  height: 16px;
  color: #4b5563;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  outline-color: transparent;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #3b82f6;
  background: #000;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 8px;
  height: 8px;
  transition-duration: 0.2s;
  background-color: #ffffff;
}

.form-switch .form-check-input {
  border-radius: 0.3em !important;
}

.divCategoriesBox select.form-control {
  max-height: 172px;
  overflow: auto;
}

.thead-bg tr th {
  background-color: #f5eaec;
  color: #000000 !important;
  z-index: 999;
}

.table td,
.table th {
  border-top: none;
}

table.dataTable.no-footer {
  border-bottom: transparent !important;
}

.tableFixHead {
  overflow: auto;
  height: 500px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
}

.searchform {
  padding: 0px 10px;
  font-size: 12px;
  font-weight: 400;
  height: 30px;
}

.customer-heading {
  font-size: 21px;
  font-weight: bold;
}

.entries-shows {
  font-size: 13px;
  color: #939393;
  display: block;
  width: 100%;
  margin-top: 6px;
}

.pagination {
  --bs-pagination-bg: transparent;
  color: #ddd;
  --bs-pagination-padding-x: 10px;
  --bs-pagination-padding-y: 2px;
  border-radius: 5px;
}

.page-link {
  color: #5f5f5f;
}

.active > .page-link,
.page-link.active {
  z-index: 3;
  color: #5f5f5f;
  background-color: #fff;
  border-color: #fff;
  border-radius: 6px;
}

.nav-tabs .btn:focus:not(:focus-visible) {
  outline: 0;
}

button:focus:not(.focus-visible) {
  outline: 0;
  /* //crossed out */
}

.space1 {
  margin-right: 6px;
}

.custom-black {
  background-color: #000;
  color: #fff;
}

.custom-black:hover {
  background-color: #000;
  color: #fff;
}

.custom-black:focus {
  background-color: #000;
  color: #fff;
}

.demobuild-txt {
  font-size: 14px;
  color: #858585;
}

.choose_colr-bg {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.7;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 8px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

input.colorpicker {
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 8px;
  margin-right: 10px;
  border: 1px solid #d9d9d9;
  padding: 6px;
}

.ematio-banner {
  position: relative;
  display: flex;
}

.ematio-banner-show {
  display: none;
  position: absolute;
  border-radius: 8px;
  background-color: #363535;
  color: #ddd;
  text-align: center;
  margin: auto;
  font-size: 13px;
  opacity: 0.9;
  padding-top: 10px;
  padding-bottom: 12px;
  width: 100%;
}

.ematio-banner:hover .ematio-banner-show {
  display: block;
}

.custom-bin {
  background-color: #cc0028;
  color: #fff;
  right: 0;
  display: block;
  width: 25px;
  padding: 4px;
  float: right;
  margin-right: 5px;
  top: 6px;
  border-radius: 8px;
  position: absolute;
}

.upload-text {
  background-color: #fff;
  padding: 18px;
  color: #707070;
  font-size: 12px;
  border-radius: 8px;
}

.choose-flie-large-custom {
  background-color: #fff;
  height: 90px;
  z-index: 999;
  position: relative;
  opacity: 0;
}

.upload-overview {
  background-color: #ffffff;
  height: 85px;
  position: relative;
  width: 100%;
  text-align: center;
  margin: auto;
  vertical-align: middle;
  margin-top: -88px;
  padding-top: 31px;
  border-radius: 8px;
  margin-left: 4px;
  z-index: 991;
}

.custom-delete {
  background-color: transparent;
  border: 1px solid #cc0028;
  border-radius: 8px;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.custom-redtext {
  color: #cc0028;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-right: 10px;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
    .invalid-feedback
  ) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.bg-red {
  background-color: red;
}

.StandardTable thead th {
  background-color: #f5eaec;
  color: #000000 !important;
}

.custom-space .bi::before {
  padding-top: 10px;
}

.time-box {
  border: 1px solid #b7b7b7;
  padding: 6px 13px;
  border-radius: 7px;
  display: inline;
  font-size: 14px;
}

.time-box span {
  color: #5fcc00;
}

.address-text {
  font-size: 10px;
  color: #8b8b8b;
  margin-bottom: 0px;
}

.table-custom2 .table > :not(caption) > * > * {
  color: #747474;
  font-size: 13px;
  font-weight: 300;
}

.text-red-custom ::placeholder {
  color: red !important;
}

.col-form-label {
  font-size: 14px !important;
  color: #212121 !important;
  font-weight: 700;
  padding: 0 !important;
}

.form-text,
.form-label {
  font-size: 13px !important;
  color: #707070 !important;
}

table.dataTable thead > tr > th.dt-orderable-asc,
table.dataTable thead > tr > th.dt-orderable-desc,
table.dataTable thead > tr > td.dt-orderable-asc,
table.dataTable thead > tr > td.dt-orderable-desc {
  cursor: pointer;
  background-color: #f5eaec;
  color: #000000 !important;
}

@media (max-width: 767px) {
  .login-details {
    top: 10px;
    left: 10px;
    width: 94%;
    margin-bottom: 20px;
  }
}

/*Customized style sheet for login and add customer end here*/
p-button.p-element.btn.btn-outline-secodary {
  padding: 0 !important;
}

.btn,
p-button.btn .p-button,
.btn:hover {
  border-radius: 6px !important;
  padding: 0.375rem 1rem !important;
  /* min-width: 86px !important; */
  cursor: pointer;
}

.btn-sm p-button.btn.btn-sm .p-button {
  padding: 0.15rem 0.5rem !important;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.175rem 0.5rem !important;
}

.btn-group-sm > .btn:hover,
.btn-sm:hover {
  padding: 0.175rem 0.5rem !important;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn:not(:first-child):not(.dropdown-toggle) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

/* .btn-primary,
p-button.btn-primary .p-button { */
.btn-primary {
  color: #fff !important;
  background-color: #3c3c3c !important;
  border: 1px solid #3c3c3c !important;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 40%) !important;
}

.btn-primary:hover,
/* p-button.btn-primary .p-button:hover, */
.btn-primary.disabled {
  background-color: #000000 !important;
  border: 1px solid #000000 !important;
}

.btn-tertiary {
  color: #000 !important;
  background-color: #f5eaec !important;
  border: 1px solid #3c3c3c !important;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 40%) !important;
}

.btn-tertiary :hover,
.btn-tertiary .disabled {
  background-color: #ecdbde !important;
  border: 1px solid #000000 !important;
}

button:not(:disabled) {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.btn-outline-primary {
  /* p-button.btn-outline-primary .p-button { */
  color: #000000 !important;
  background-color: transparent !important;
  border: 1px solid #000000 !important;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 40%) !important;
}

.btn-outline-primary:hover {
  /* p-button.btn-outline-primary .p-button:hover { */
  color: #fff !important;
  background-color: #000000 !important;
  border: 1px solid #000000 !important;
}

.btn-outline-secondary,
td .p-button {
  color: #cc0028 !important;
  background-color: transparent !important;
  border: 1px solid #cc0028 !important;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(105 108 255 / 40%) !important;
}

.btn-outline-secondary:hover,
td .p-button:hover {
  color: #fff !important;
  background-color: #b90226 !important;
  border: 1px solid #cc0028 !important;
}

.btn-white {
  background-color: #fff;
  color: #000;
  padding: 5px !important;
  border-radius: 6px;
}

.btn-white:hover {
  background-color: #cc0028 !important;
  color: #fff !important;
}

.btn-white:focus,
btn-white::selection {
  background-color: #cc0028 !important;
  color: #fff !important;
}

.btn-white:hover img {
  filter: brightness(0) invert(1);
  /* Change image to white */
}

footer.footer-main {
  font-size: 14px;
  font-weight: 700;
  clear: both;
}

.footer-main {
  display: flex;
  align-items: center;
  height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0;
  z-index: 1000;
}

footer.footer-main img {
  max-height: 28px;
  margin-left: 2px !important;
}

.toolbar,
.toolbaroxiD,
.toolbarHSTD,
.toolbarRnED,
.toolbarPhysician,
.toolbarPatient,
.toolbarCategory {
  float: left;
}

.toolbar h1,
h1 {
  font-size: 24px !important;
  letter-spacing: 0px;
  color: #212121 !important;
  font-weight: 600 !important;
  margin-bottom: 20px !important;
  padding-top: 3px;
}

h2 {
  font-size: 20px !important;
  letter-spacing: 0px;
  color: #212121 !important;
  font-weight: 600 !important;
}

h3 {
  font-size: 18px !important;
  letter-spacing: 0px;
  color: #212121 !important;
  font-weight: 600 !important;
}

.tableBtn,
.tableBtnoxiD,
.tableBtnhstD,
.tableBtnRnED,
.tableBtnPhysician,
.tableBtnPatient,
.tableBtnCategory {
  float: right;
}

/*Icon selection Radio button with images */
/* HIDE RADIO */
/*.selecteIcon [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}*/

/* IMAGE STYLES */
/*.selecteIcon [type=radio] + img {
        cursor: pointer;
    }*/

/* CHECKED STYLES */
/*.selecteIcon [type=radio]:checked + img {
        outline: 2px solid #f00;
    }
.selecteIcon input[type="radio"]:checked +img  {
    border: 1px solid red;
}*/

.selecteIcon input[type="radio"] {
  position: absolute;
  left: -9999px;
}

.selecteIcon .form-check-inline {
  display: inline-block;
  margin: 0 !important;
  padding: 0 !important;
}

.selecteIcon input[type="radio"]:hover {
  background: red;
  color: #fff;
}

/* Stuff after this is only to make things more pretty */
.selecteIcon input[type="radio"] + label > img {
  /*border: 1px dashed #444;*/
  width: 24px;
  height: 24px;
  /*transition: 500ms all;*/
}

.selecteIcon input[type="radio"] + label {
  /*transform: rotateZ(-10deg) rotateX(10deg);*/
  background-color: #fff !important;
  color: #212121 !important;
  padding: 6px;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(105 108 255 / 40%) !important;
  border-radius: 6px !important;
}

.selecteIcon input[type="radio"]:checked + label,
.selecteIcon:hover input[type="radio"]:hover + label {
  /*transform: rotateZ(-10deg) rotateX(10deg);*/
  background-color: #cc0028 !important;
  color: #fff !important;
}

.selecteIcon input[type="radio"]:checked + label > img,
.selecteIcon input[type="radio"]:hover + label > img {
  filter: brightness(0) invert(1);
  /* Change image to white */
  /*box-shadow: 0 0 3px 3px #090;*/
  cursor: pointer;
}

.w-200p {
  width: 200px;
}

.dt-scroll-body {
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 465px) !important;
  height: auto !important;
}

.btn-outline-dark {
  color: #000 !important;
  background-color: transparent !important;
  border: 1px solid #000;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(105 108 255 / 40%) !important;
}

.btn-outline-dark:hover {
  color: #000 !important;
  background-color: #fff !important;
  border: 1px solid #000;
}

.w-55 {
  width: 55px !important;
  min-width: 55px !important;
}

.w-65 {
  width: 65px !important;
  min-width: 65px !important;
}

.w-76 {
  width: 76px !important;
  min-width: 76px !important;
}

.w-140 {
  width: 140px !important;
  min-width: 140px !important;
}

.w-160 {
  width: 160px !important;
  min-width: 160px !important;
}

.w-selSP {
  width: 240px;
  max-width: 240px;
}

.w-selEV {
  width: 450px;
  max-width: 450px;
}

.spnDollar {
  color: #8e918f;
  line-height: 1.7;
  margin-right: 4px;
  font-size: 90%;
  /* display: ; */
}

/* Freeze Table Header,footer and first colomn */
/* table th {
    padding: .25rem .5rem !important;
} */

div.divTable {
  max-width: 100%;
  max-height: calc(100vh - 421px);
  min-height: auto !important;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
}

div.divTable table {
  position: relative;
  border-collapse: collapse;
  width: 99.7% !important;
}

div.divTable thead th {
  background-color: #f5eaec !important;
  color: #000000 !important;
  z-index: 999;
}

div.divTable thead th,
div.divTable thead td {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  top: 0;
  /* background: #F5EAEC !important; */
  /* color: #212121; */
  white-space: nowrap;
}

table.dataTable > thead > tr > th,
table.dataTable > thead > tr > td {
  padding: 10px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

div.divTable tfoot th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  /*top: 100;*/
  background: #000 !important;
  color: #fff;
}

/*
        div.divTable thead th:first-child, div.divTable tfoot th:first-child {
            left: 0;
            z-index: 1;
        }
*/
div.divTable tbody th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  left: 0;
  background: #fff;
  border-right: 1px solid #ccc;
}

.countdown {
  margin-right: 1rem;
  min-width: 94px;
}

.divShowallpages {
  position: sticky;
  left: 0px;
  top: 0;
  z-index: 999;
}

.noData {
  /* font-size: 18px;
    font-weight: bold; */
  padding: 36px;
  text-align: center;
  opacity: 0.6;
}

@media (max-width: 820px) {
  .truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .w-selSP {
    font-size: 12px !important;
    height: 23.31px;
  }
}

/* Starts Income Calculator */
.divCategories .divCategoriesBox {
  width: 46%;
}

.divCategories .divButtons {
  width: 8%;
  padding: 1%;
  min-width: 42px;
}

.box .form-control {
  /* background-color: #fff; */
  color: #000;
  height: 280px;
  border: 0px !important;
  padding: 0 !important;
}

.divCounts {
  /* display: block; */
  font-size: 86%;
  margin: 0rem 0 0 0.1rem;
  font-weight: 500;
  color: #007acc;
}

span.spanValue {
  font-weight: 700;
  margin-left: 0.5rem;
}

/* Ends Income Calculator */

/* Transactio Reviews */
/* .p-multiselect {
    width: 100%;
    margin-top: 3px;
    border-radius: 5px;
    border: solid 1px #DCDCDC !important;
    color: #212121;
    font-size: 16px !important;
    padding: 0.01rem 0.4rem !important;
    line-height: 1.5;
    height: 25.65px;

} */

.p-multiselect .p-multiselect-trigger {
  width: 1rem !important;
}

.p-multiselect-panel .p-multiselect-header,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 1rem 0.45rem !important;
  font-size: 14px;
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0 !important;
}

.p-checkbox .p-checkbox-box {
  width: 16px;
  height: 16px;
}

.p-multiselect-filter {
  padding: 2px;
  /* border: none; */
}

.tddescription {
  min-width: 30% !important;
  width: 200px !important;
}

.tr-input-field {
  width: 100%;
  font-size: 13px;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 3px;
  outline: none;
}

.tr-input-field:hover {
  border: 1px solid #2196f3;
  transition: border-color 0.3s ease;
}

.h-26 {
  height: 26px;
}

/* .p-multiselect-label {
    padding: 0 6px 0 12px;
    font-size: 14px;
} */
.p-multiselect .p-multiselect-label {
  padding: inherit !important;
  max-width: 154px;
}

.input-filed-control {
  background: #ffffff;
  border: 1px solid #d1d5db;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
  height: 24px;
  font-size: 15px;
  padding: 3px 12px;
  margin-top: 2px;
}

.flagicon {
  border-radius: 2px;
  color: #ffffff !important;
  margin-left: 0.2rem;
  min-width: 6px;
}

.amtIcon {
  font-size: 20px;
  display: inline;
  line-height: 0.2;
  font-weight: bold !important;
  letter-spacing: normal !important;
}

.p-datatable .p-datatable-thead > tr > th {
  background: #f5eaec !important;
  color: #000000 !important;
}

.p-datatable {
  border-collapse: separate !important;
  border-spacing: 0 0.5em !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 50% !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e5e7eb;
  border-width: 0 0 1px 0;
}

.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem !important;
  font-size: 14px !important;
}

#LoanTable th {
  white-space: nowrap;
}

#LoanTable .p-datatable-wrapper .p-scroller-viewport > div {
  height: calc(100vh - 180px) !important;
  /* max-height: calc(100vh - 200px) !important; */
}

#clientsTable .p-datatable-wrapper .p-scroller-viewport > div {
  height: calc(100vh - 224px) !important;
  /* max-height: calc(100vh - 200px) !important; */
}

#LoanTable td {
  max-width: 100%;
  max-width: 100px;
  color: #000;
}

#LoanTable td span,
#LoanTable td a {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  width: fit-content;
  display: block;
}

#LoanTable td button.p-ripple.p-element.p-button.p-component {
  padding: 0rem 0.6rem !important;
  border-radius: 4px !important;
}

#LoanTable th .escalation {
  color: #0f0f0f;
  border: solid 1px rgba(0, 0, 0, 0.2) !important;
  padding: 0rem 0.5rem !important;
  border-radius: 4px;
  text-align: center;
  width: 20px;
}

.p-datatable-table {
  border-collapse: separate;
  border-spacing: 0 0.5em;
  margin-top: -10px !important;
  width: 99.7%;
  margin-bottom: 10px !important;
}

.p-datatable .p-datatable-header {
  background: transparent;
  color: inherit;
  border: none;
  border-width: 0px;
  padding: 0rem 0rem;
  font-weight: 700;
  /* display: flex; */
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: space-between;
}

.p-datatable-scrollable-table > .p-datatable-thead {
  position: sticky;
  top: -1px;
  z-index: 2;
}

.p-paginator {
  background: transparent;
  color: inherit;
  border: solid #f3f4f6;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 0 !important;
}

/* .p-datatable .p-datatable-header div {
    background: white !important;
    width: 290px;
    padding: 0 10px;
    border-radius: 10px;
    border: solid 1px #ced4da;
    margin: 0;
} */

.p-datatable .p-datatable-header div i {
  opacity: 0.5;
}

/* .p-datatable .p-datatable-header div input {
    border: none;
    background: transparent;
    padding: 0.461rem .75rem 0.461rem 2.65rem !important;
    border: 1px solid #ced4da !important;
    border-radius: 10px !important;
} */

input.p-inputtext.p-component.p-element:focus {
  /* border: 0px !important; */
  outline: none !important;
  box-shadow: none !important;
}

/* .p-paginator .p-paginator-pages .p-paginator-page {    min-width: 2rem;
    height: 2rem;

}
.p-inputtext {
    padding: 0.3rem .5rem !important;
} */

.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2rem;
  height: 2rem;
}

.p-paginator .p-dropdown {
  height: 2rem;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  border-radius: 50% !important;
  min-width: 2rem;
  height: 2rem;
}

td p-button {
  line-height: 0.5rem;
}

button.p-column-filter-menu-button.p-link {
  border-radius: 4px !important;
}

.p-column-filter-buttonbar {
  display: none;
}

.p-button {
  color: inherit !important;
  background: inherit !important;
  border: none !important;
  padding: 0 !important;
  font-size: inherit !important;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: inherit !important;
  outline-color: inherit !important;
}

.p-dialog .p-dialog-content {
  padding: 0 0.8rem 0.8rem 0.8rem;
}

.p-dialog .p-dialog-header {
  padding: 0.8rem 0.8rem 0 0.8rem;
}

.p-multiselect-filter.p-inputtext {
  padding: 0.3rem 2rem 0.3rem 0.5rem !important;
}

.tdEmpty {
  margin: 6%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.intGroup-incomeCalc .material-symbols-outlined {
  font-size: 20px !important;
}

.intGroup-incomeCalc .btn {
  padding: 0.1rem 0.6rem !important;
}

.intGroup-incomeCalc input {
  background-color: #f2f2f2 !important;
  font-weight: bold;
  border: solid 1px #212121 !important;
  border-radius: 5px;
  padding: 0.09rem 0.4rem !important;
  /* box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 40%) !important; */
}

.intGroup-incomeCalc input:focus {
  /* background-color: #FFE535 !important; */
  font-weight: bold;
}

.intGroup-incomeCalc .btn {
  height: 28.88px;
  margin-top: -5px;
  line-height: 1.1;
}

.mt-6 {
  margin-top: 2.3rem !important;
}

.amount-column input {
  text-align: right;
  max-width: 120px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-multiselect-label .p-placeholder {
  color: black !important;
}

.p-multiselect-items-wrapper {
  max-height: 10rem !important;
}

.card-incomeCalc .col-6,
.card-incomeCalc .col-7,
.card-incomeCalc .col-5 {
  margin-bottom: 0.4rem;
}

#rulesTable th {
  white-space: nowrap;
}

#rulesTable .p-datatable-wrapper .p-scroller-viewport > div {
  height: calc(100vh - 180px) !important;
}

#clientsTable .p-datatable-wrapper .p-scroller-viewport > div {
  height: calc(100vh - 218px) !important;
}

#transactionReviewTable .p-datatable-wrapper .p-scroller-viewport > div {
  height: calc(100vh - 218px) !important;
}

#rulesTable td {
  max-width: 100%;
  /* max-width: 100px; */
  color: #000;
}

#rulesTable td span {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  width: fit-content;
  display: block;
}

#rulesTable td button.p-ripple.p-element.p-button.p-component {
  padding: 0rem 0.6rem !important;
  border-radius: 4px !important;
}

#rulesTable th .escalation {
  color: white;
  border: solid 1px white !important;
  padding: 0rem 0.2rem !important;
  border-radius: 4px;
}

/* changes */

.imgThumbnail img {
  padding: 6px;
  width: 100%;
  border-radius: 10px !important;
}

.mat-expansion-indicator {
  color: #cccccc;
}

.mat-expanded .mat-expansion-indicator {
  margin-right: 6.9px !important;
}

.pdf-list-view .img-list-view .list-item-view .list-item.selected {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-color: #007acc;
}

.pdf-list-view .img-list-view .list-item-view .list-item.selected .pageno,
.pdf-list-view .img-list-view .list-item-view .list-item.selected .imgThumbnail,
.selected .pageno,
li.selected .imgThumbnail {
  background-color: #f5eaec !important;
}

.mat-expansion-panel-header-title {
  margin-right: 31px !important;
}

.pdf-list-view .img-list-view .list-item-view .list-item.selected .pageno::before {
  content: "\F270";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  position: absolute;
  right: 6px;
  /* top: 0; */
  color: #21a67a;
  -webkit-font-smoothing: antialiased;
  z-index: 10;
  font-size: 20px;
  border-radius: 50%;
  /* background: white;
    box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 40%) !important; */
}

.mat-expansion-panel-header-title {
  margin-right: 31px !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration,
.p-dropdown-clear-icon {
  -webkit-appearance: none;
  display: none;
}

ul.p-dropdown-items {
  margin: 0 auto !important;
  padding: 0;
}

.modal-header {
  padding: 0.5rem 1rem !important;
}

.card.card-noBG {
  background: transparent;
  border: 0;
  box-shadow: none;
}

.p-icon.pi-filter-icon {
  background: url(assets/images/filter-icon.png) center no-repeat;
  background-size: contain;
  width: 14.25px;
  height: 18px;
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
}

.p-icon.pi-filter-icon g {
  display: none;
}

.mat-expansion-panel-header-title {
  margin-right: 31px !important;
}

.w-74px {
  min-width: 74px !important;
}

mat-card-content div mat-card-content {
  max-height: calc(100vh - 610px) !important;
  overflow: auto;
  height: calc(100vh - 650px) !important;
  min-height: calc(100vh - 355px) !important;
}

mat-card.document-view mat-card-title a,
mat-card.document-list-view mat-card-title a {
  color: #212121 !important;
  font-size: 12px !important;
  cursor: pointer;
  padding: 0rem 0.5rem !important;
}

mat-card.document-view mat-card-title a:hover {
  padding: 0rem 0.5rem !important;
}

.p-inputtext.p-component.p-element.p-inputnumber-input.p-filled.isBalanceErrorCell {
  background-color: red !important;
}

span.clearfilter {
  border-radius: 4px !important;
}

.clearAllFilters_ico {
  border: none;
}

.editIcon {
  background-image: url("assets/images/edit.svg");
  width: 20px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat;
}

.deleteIcon {
  background-image: url("assets/images/trash.svg");
  width: 18.2px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat;
}

/* Hierarchy */
ul.hierarchy {
  margin: 0;
  padding: 0;
}

ul.hierarchy li {
  list-style-type: disc;
}

ul.hierarchy li::marker {
  content: "+";
}

ul.hierarchy li::before {
  border-left: solid 1px dashed;
}

.undoIcon {
  background-image: url("assets/images/undoicon.svg");
  width: 18.2px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat;
}

.addFieldEntity {
  background-image: url("assets/images/AddEntity.svg");
  width: 18px;
  height: 18px;
  display: inline-block;
  background-repeat: no-repeat;
  cursor: pointer !important;
}

.addSource {
  background-image: url("assets/images/AddSource.svg");
  width: 18px;
  height: 18px;
  display: inline-block;
  background-repeat: no-repeat;
  cursor: pointer !important;
}

.sourcepercentage {
  background-image: url("assets/images/sourcepercentage.svg");
  width: 16px;
  height: 16px;
  display: inline-block;
  background-repeat: no-repeat;
  cursor: pointer !important;
}

.btnFieldEntity {
  border: 0;
  position: absolute;
  left: -6px;
  background: transparent;
  margin-top: -3px;
}

/* .p-tooltip {
    position: absolute;
    display: none;
    padding: .1em .5rem !important;
    max-width: 12.5rem;
    pointer-events: none;
} */

.p-tooltip .p-tooltip-text {
  padding: 0.2em 0.6rem !important;
  font-size: 90%;
}

/* Data Extraction Styles */
.table_DataExtraction .p-datatable-table tr th:first-child,
.table_DataExtraction .p-datatable-table tr td:first-child,
.table_DataExtraction .p-datatable-table tr th:last-child,
.table_DataExtraction .p-datatable-table tr td:last-child {
  border-radius: 0px 0 0 0px !important;
}

.table_DataExtraction .p-treetable .p-treetable-thead > tr > th {
  line-height: 1;
}

.table_DataExtraction .p-treetable tr th.defaultBG {
  background-color: #f9cdd5 !important;
}

.table_DataExtraction .p-treetable tr th.sourceHeader,
.table_DataExtraction .p-treetable tr th.sourceHeader1,
.table_DataExtraction .p-treetable tr th.sourceHeader4 {
  background-color: #f7c7ac !important;
}

.table_DataExtraction .p-treetable tr th.sourceHeader2,
.table_DataExtraction .p-treetable tr th.sourceHeader5 {
  background-color: #94dcf8 !important;
}

.table_DataExtraction .p-treetable tr th.sourceHeader3,
.table_DataExtraction .p-treetable tr th.sourceHeader6 {
  background-color: #c1f0c8 !important;
}

.table_DataExtraction .p-treetable {
  border-collapse: inherit !important;
  border-spacing: 0 !important;
}

.table_DataExtraction .p-datatable .p-datatable-thead > tr > th {
  line-height: 1;
}

.table_DataExtraction .p-datatable-table tr th.defaultBG {
  background-color: #f9cdd5 !important;
}

.table_DataExtraction .p-datatable-table tr th.sourceHeader,
.table_DataExtraction .p-datatable-table tr th.sourceHeader4 {
  background-color: #f7c7ac !important;
}

.table_DataExtraction .p-datatable-table tr th.sourceHeader2,
.table_DataExtraction .p-datatable-table tr th.sourceHeader5 {
  background-color: #94dcf8 !important;
}

.table_DataExtraction .p-datatable-table tr th.sourceHeader3,
.table_DataExtraction .p-datatable-table tr th.sourceHeader6 {
  background-color: #c1f0c8 !important;
}

.table_DataExtraction .p-datatable-table {
  border-collapse: inherit !important;
  border-spacing: 0 !important;
}

.table_DataExtraction .input-group-text {
  margin: 0px !important;
  padding: 0px !important;
  width: 34px !important;
}

.table_DataExtraction .input-group {
  margin: 0px !important;
  padding: 0px !important;
  width: 100%;
}

.table_DataExtraction .p-datatable-table td:first-child {
  /* background-color: red; */
  padding-left: 24px !important;
}

.divider {
  border-right: solid 1px #edeaf2;
  width: 0px;
  height: 100vh;
  margin: 0 1%;
}

.tree-structure {
  list-style: none;
  clear: both;
  margin-left: 15px;
  border-left: solid 1px red;
}

/* .tree-structure>li {
    display: inline-block;
    background: #333;
    min-width: 24px;
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
    padding: 3px 9px;
    margin-right: 10px;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
} */

.tree-structure > li:after {
  /* position: absolute; */
  content: "+";
  width: 1px;
  height: 100%;
  background-color: #939393;
  top: 5px;
  left: 12px;
  z-index: -1;
}

.tree-structure > li:last-child:after {
  height: calc(100% - 44px);
}

.tree-structure ol {
  padding: 20px 0 20px 45px;
}

.tree-structure ol li {
  list-style-type: none;
  padding: 8px 0;
}

.tree-structure ol li .num {
  position: relative;
}

.tree-structure ol li a {
  color: #000;
  font-weight: normal;
}

/* ————————————————————–
  Tree core styles
*/
.tree {
  margin: 1em 0 0 0;
}

/* ————————————————————–
  Tree rows
*/
.tree li {
  line-height: 1.2;
  position: relative;
  padding: 0 0 1em 1em;
  list-style-type: none;
}

.tree ul li {
  padding: 1em 0 0 1.5em;
}

.tree > li:last-child {
  padding-bottom: 0;
}

/* ————————————————————–
  Tree labels
*/
.tree_label {
  position: relative;
  display: inline-block;
  /* background: #fff; */
}

label.tree_label {
  color: #000000;
  font-weight: bold;
  font-size: 14px;
}

/* ————————————————————–
  Tree expanded icon
*/
label.tree_label:before {
  background: #707070;
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  margin: 0 0.5em 0 0em;
  width: 1em;
  height: 1em;
  border-radius: 0.2em;
  content: "+";
  text-align: center;
  line-height: 0.9em;
  font-size: 20px;
  font-weight: bold;
}

:checked ~ label.tree_label:before {
  content: "–";
}

/* ————————————————————–
  Tree branches
*/
ul.tree,
ul.tree ul {
  padding: 0;
  margin: 0;
}

.tree ul:first-child li {
  padding: 0rem 0 1rem 0rem;
}

.tree li:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.6em;
  display: block;
  width: 0;
  border-left: 2px dashed #777;
  content: "";
  border-bottom: 2px dashed #777;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
}

.tree li:not(:has(ul)) label {
  color: #2b982f !important;
}

.tree li:not(:has(ul)) label.tree_label:before {
  background: #2b982f !important;
}

/* .tree_label:after {
    position: absolute;
    top: 0;
    left: -1.5em;
    display: block;
    height: 0.5em;
    width: 1em;
    border-bottom: 2px dashed #777;
    border-left: 2px dashed #777;
    border-radius: 0 0 0 .3em;
    content: '';
}

label.tree_label:after {
    border-bottom: 0;
} */

:checked ~ label.tree_label:after {
  border-radius: 0 0.3em 0 0;
  border-top: 1px solid #777;
  border-right: 1px solid #777;
  border-bottom: 0;
  border-left: 0;
  bottom: 0;
  top: 0.5em;
  height: auto;
}

.tree li:last-child:before {
  height: 1em;
  bottom: auto;
}

.tree > li:last-child:before {
  display: none;
}

.tree_custom {
  display: block;
  background: #eee;
  padding: 1em;
  border-radius: 0.3em;
}

.p-inputswitch {
  width: 29px !important;
  height: 1.1rem !important;
}

.p-inputswitch .p-inputswitch-slider {
  background: #fff;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 0.3em !important;
  border: solid 1px #bfbfbf;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #bfbfbf;
  width: 10px;
  height: 10px;
  left: 0.15rem;
  margin-top: -0.325rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(0.7rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfdbfe;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #ffffff;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #000000;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #000000;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch > .p-inputswitch-slider {
  border-color: #e24c4c;
}

.AccuracyParameters {
  position: absolute;
  top: 0;
  background-color: #f5eaec !important;
  font-size: 80%;
  padding: 0 12px;
  margin-left: -12px;
  border-radius: 10px 0 0 0;
}

.p-dialog-footer {
  text-align: center;
}

tr.p-element.table-body-row {
  border-bottom: 1px solid #f5eaec !important;
}

tr td:last-child.tdEmpty {
  border-radius: 10px 10px 10px 10px !important;
}

.hiddenCanvasElement {
  display: none;
}

.exclamation-triangle-icon {
  text-align: center;
}

.exclamation-triangle-icon i {
  font-size: 70px;
  color: #c21d1d;
}
